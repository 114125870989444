import React, {useEffect, useState} from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import KLAYTN from '../../img/chains/KLAYTN.png';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import apiClient from "../../apiClient";

const PriceLabel = ({tokenPrice, currency}) => {
    const [price, setPrice] = useState(0);

    useEffect(() => {
        (async () => {
            const { data } = await apiClient.get("blockchain/prices/corn");
            console.log(`blockchain/prices/corn = ${JSON.stringify(data)}`);
            const { status, price } = data;
            if(status === 'ok') {
                const {usdPrice} = price;
                console.log(`usdPrice = ${usdPrice}`);
                const currencyPrice = Number(tokenPrice) * Number(usdPrice);
                console.log(`currencyPrice = ${currencyPrice}`);

                setPrice(currencyPrice);
            }
        })();
    }, [tokenPrice]);

  return (
          <span className="ml-2 mt-2 text-sm text-jacarta-400 dark:text-jacarta-300">
          =
              {price &&
            (price).toLocaleString("en")
          }
                    원
          </span>
  );
};

export default PriceLabel;

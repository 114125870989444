import React, { useState } from "react";
import { Modal } from "./Modal";
import { ModalBodyBuy } from "../ModalBodyBuy";
import { ethers } from "ethers";
import { saleAbi } from "../../blockchain/saleAbi";
import { erc20Abi } from "../../blockchain/erc20Abi";
import { cornAbi } from "../../blockchain/cornAbi";
import { constants } from "../../blockchain/constants";
import { currencyById } from "../../blockchain/currencies";
import apiClient from "../../apiClient";
import { ModalBodySell } from "../ModalBodySell";
import { ModalBodyCancel } from "../ModalBodyCancel";
const CancelSellModal = ({
  setModalIsOpen,
  item,
  styledWrapper,
  setPageLoading,
  setForceRefresh,
  refreshPage,
  balance,
}) => {
  const [inputPrice, setInputPrice] = useState("0");
  return (
    <Modal
      setModalIsOpen={setModalIsOpen}
      title="Cancel"
      btnTitle="Cancel now"
      childrenBody={
        <ModalBodyCancel
          item={item}
          balance={balance}
          onChangePrice={(event) => {
            setInputPrice(event.target.value);
          }}
        />
      }
      styledWrapper={styledWrapper}
      onClick={async function () {
        try {
          const checkbox = document.getElementById("cancelNowTerms");
          if (checkbox && !checkbox["checked"]) {
            alert("서비스 약관에 동의해주세요.");
            return;
          }
          // we must not render on sale
          if (item.saleStatus !== "ON_SALE") {
            alert("판매중이 아닙니다.");
            return;
          }
          const ethereum = window["ethereum"];
          if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            const saleContract = new ethers.Contract(
              constants.SALE,
              saleAbi,
              signer
            );
            if (signerAddress.toLocaleLowerCase() !== item.userAddress) {
              alert("본인 NFT가 아닙니다.");
              return;
            }
            const currencyAddress = currencyById(3); //currencyById(item.currencyId);
            setPageLoading(true);

            const nftAddress = item.nftAddress; // from collectionAssets table
            const tokenId = item.tokenId; // from collectionAssets table
            const price = ethers.utils.parseUnits(inputPrice, 18);
            const { saleInfoId } = item;
            console.log(`
              [SELL]
              nftAddress = ${nftAddress},
              tokenId = ${tokenId},
              price = ${price},
              saleInfoId = ${saleInfoId}
              `);
            const tx = await saleContract.cancelSale(saleInfoId);
            const receipt = await tx.wait();
            console.log(receipt);

            const response = await apiClient.post("blockchain/updateNft");
            if (response.data.result === "ok") {
              setTimeout(() => {
                refreshPage();
                setForceRefresh((prev) => !prev);
                setPageLoading(false);
                setModalIsOpen(false);
                alert("취소에 성공했습니다.");
              }, 10000);
            }
          }
        } catch (e) {
          alert(`mobile env debugging, error = ${e}`);
        }
      }}
      onClickClose={function () {}}
    />
  );
};

export { CancelSellModal };
